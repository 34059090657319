<template>
  <div class="front-home-page">
    <section class="seven">
      <div class="container">
        <div class="row">
          <div
            class="col-12 featured-in"
            data-aos="zoom-in-down"
            data-aos-once="true"
            data-aos-duration="1000"
          >
            <h1>Featured In</h1>
          </div>
        </div>

        <div class="row">
          <div
            class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 featured-in-item"
            data-aos="zoom-in-down"
            data-aos-once="true"
            data-aos-duration="1000"
            v-for="(row, index) in featuredIn"
            :key="index"
          >
            <a
              :href="row.link"
              target="_blank"
              class="featured-box"
            >
              <img v-if="row.image" :src="row.baseUrl + '/' + row.image" />
              <img v-else src="../../../assets/image/front/Oval1.png" />
              <h4>{{ row.name }}</h4>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      featuredIn: [],
    };
  },
  mounted() {
    this.getfeaturedInData();
  },
  created() {
    this.getfeaturedInData();
  },
  computed: {
    ...mapGetters("frontcms", ["featuredInData"]),
  },
  watch: {
    featuredInData: {
      immediate: true,
      handler(featuredInData) {
        this.featuredIn = featuredInData;
      },
    },
  },
  methods: {
    ...mapActions("frontcms", ["getfeaturedInData"]),
  },
};
</script>
