import Home from "../pages/frontend/cms/home.vue";
import FeaturedIn from "../pages/frontend/cms/featuredin.vue";
import AboutUs from "../pages/frontend/cms/aboutus.vue";
import ContactUs from "../pages/frontend/cms/contactus.vue";
import TermsAndConditions from "../pages/frontend/cms/termsandconditions.vue";
import Blogs from "../pages/frontend/cms/blogs.vue";
import BlogDetail from "../pages/frontend/cms/blogdetail.vue";
import MainLayout from "../layoutfront/main.vue";
import OurServices from "../pages/frontend/cms/ourservice.vue"
import Solution from "../pages/frontend/cms/solution.vue"
import Page404 from "../pages/common_users/404";
import TermsOfUse from "../pages/frontend/cms/termsofuse.vue";
import PrivacyPolicy from "../pages/frontend/cms/privacypolicies.vue";

export default {
  path: "/",
  component: MainLayout,
  children: [
    // {
    //     path: "/",
    //     redirect: "/home",
    // },
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/featured-in",
      name: "FeaturedIn",
      component: FeaturedIn,
    },
    {
      path: "/404",
      name: "404",
      component: Page404,
    },
    {
      path: "/terms-and-conditions",
      name: "TermsAndConditions",
      component: TermsAndConditions,
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
    },
    {
      path: "/terms-of-use",
      name: "TermsOfUse",
      component: TermsOfUse,
    },
    {
      path: "/about-us",
      name: "AboutUs",
      component: AboutUs,
    },
    {
      path: "/contact-us",
      name: "ContactUs",
      component: ContactUs,
    },
    {
      path: "/blogs",
      name: "Blogs",
      component: Blogs,
    },
    {
      path: "/blog/:slug",
      name: "BlogDetail",
      component: BlogDetail,
    },
    {
      path: "/ourservices",
      name: "OurServices",
      component: OurServices,
    },
    {
      path: "/solution",
      name: "Solution",
      component: Solution,
    },
  ],
};