
import axios from "axios";

const AUTH_PARAMS = {
    grant_type: 'password',
    client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
    client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
};

function initialState() {
    let featuredInData = [];
    let featuredInDataSlider = [];
    return {
        featuredInData: featuredInData,
        featuredInDataSlider: featuredInDataSlider,
    }

}

const getters = {
    featuredInData: state => state.featuredInData,
    featuredInDataSlider: state => state.featuredInDataSlider,
};

const actions = {
    getfeaturedInData: ({ commit }) => {
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/featured-in")
            .then((response) => {
                console.log(response.data);
                commit("setfeaturedInData", response.data.data);
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    getfeaturedInDataSlider: ({ commit }) => {
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/featured-in/slider")
            .then((response) => {
                console.log(response.data);
                commit("setfeaturedInDataSlider", response.data.data);
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    getSinglefeaturedInData: ({ commit }, data) => {
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .get(process.env.VUE_APP_ROOT_API + "/featured-in/" + id)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    createfeaturedInData: ({ dispatch }, data) => {
        // commit("setLoading", true);
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/featured-in/create", data, {...AUTH_PARAMS,})
            .then((response) => {
                dispatch("getfeaturedInData");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    updatefeaturedInData: ({ dispatch },data) => {
        // commit("setLoading", true);
        let id = data.id;
        let formData = data.formData;
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/featured-in/edit/" + id, formData, {...AUTH_PARAMS})
            .then((response) => {
                dispatch("getfeaturedInData");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
    deletefeaturedInData: ({ dispatch },data) => {
        // commit("setLoading", true);
        let id = data.id;
        return new Promise((resolve, reject) => {
        axios
            .post(process.env.VUE_APP_ROOT_API + "/featured-in/delete/" + id,{...AUTH_PARAMS})
            .then((response) => {
                dispatch("getfeaturedInData");
                resolve(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.code === 401) {
                    return reject(error.response.message);
                }
                reject(error);
            });
        });
    },
};

const mutations = {
    setfeaturedInData: (state, value) => {
        state.featuredInData = value;
    },
    setfeaturedInDataSlider: (state, value) => {
        state.featuredInDataSlider = value;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations: mutations,
    getters: getters,
    actions: actions
};