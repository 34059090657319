<template>
    <div class="testimonial testimonial-list">
        <div class="content-header">
            <span class="h2">Featured In</span>
            <div class="page-header-right">
                <button-component @onBtnClick="createFeaturedIn" :clsname="'btnprimary'">Create Featured In</button-component>
            </div>
        </div>
        <div class="content">
            <div class="box margino">
                <div class="box-content">
                    <div class="datatable-main">
                        <div class="data-table-header">
                            <div class="data-table-header-inner">
                                <search-bar
                                    class="header-searchbar"
                                    :classname="classname"
                                    :type="type"
                                    :inputPlaceholder="inputPlaceholder"
                                    v-model="filter"
                                ></search-bar>
                            </div>
                        </div>
                        <datatable-component
                            class="dtable-main"
                            :selected-rows="selectedRows"
                            :columns="columns"
                            :rows="rows"
                            :type="type"
                            :inputPlaceholder="inputPlaceholder"
                            :classname="classname"
                            :filter="filter"
                            :page="page"
                            :per_page="per_page"
                        ></datatable-component>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
            <div :class="{'modal-window': true , 'popupopen': isView}">
                <div class="modal-window-main">
                    <a title="Close" class="modal-close"><img @click="togglePopUp" src="../../../../assets/image/cancel.svg" class="cancel-icon" alt="cancel-icon"/></a>
                    <div class="header">
                        <text-component :class="'h2'">{{item.id?'Edit':'Create'}} Featured In</text-component>
                    </div>
                    <div class="row margino">
                        <input-component
                            class="txtgroup col-12"
                            v-model="item.name"
                            required="true"
                            :helptext="!$v.item.name.required && submitted ? validationmessage.name: false"
                            :labelclass="{'validationerr': !$v.item.name.required && submitted}"
                            :classname="{'error': !$v.item.name.required && submitted, 'default': true}">
                            Name
                        </input-component>
                        <div class="col-12">
                            <profile-uploader
                                :imageurl="imageurl"
                                :fileuploadid="fileuploadid"
                                @selectfile="getfiledata"
                                :avatar="avatar"
                            ></profile-uploader>
                        </div>
                        <input-component
                            class="txtgroup col-12"
                            v-model="item.link"
                            required="true"
                            :helptext="!$v.item.link.required && submitted ? validationmessage.link: false"
                            :labelclass="{'validationerr': !$v.item.link.required && submitted}"
                            :classname="{'error': !$v.item.link.required && submitted, 'default': true}">
                            Link
                        </input-component>
                    </div>
                    <hr class="hrline" />
                    <div class="modal-footer">
                        <div>
                            <button-component v-if="item.id" @onBtnClick="handleFeaturedInUpdate" :clsname="btninvitecls">Update</button-component>
                            <button-component v-else @onBtnClick="handleFeaturedIn" :clsname="btninvitecls">Create</button-component>
                        </div>
                    </div>
                </div>
            </div>
        <!-- /Modal -->
    </div>
</template>

<script>
import DatatableComponent from "../../../../BasicComponents/Datatable/dtable.vue";
import ButtonComponent from "../../../../BasicComponents/Button/Button.vue";
import InputComponent from "../../../../BasicComponents/Input Group/inputGroup.vue";
import TextComponent from "../../../../BasicComponents/Text/Text.vue";
// import TextAreaComp from "../../../../BasicComponents/Input/textarea.vue";
import Action from "./delete.vue";
import EditBtn from "./editicon.vue";
import searchBar from "../../../../BasicComponents/Input/Input.vue";
import { mapGetters,mapActions } from "vuex";
import ProfileUploader from "./fileupload.vue";
import { required } from "vuelidate/lib/validators";
import imageIconVue from './imageIcon.vue';
export default {
    components: {
        DatatableComponent,
        searchBar,
        ProfileUploader,
        ButtonComponent,
        InputComponent,
        TextComponent,
        // TextAreaComp
    },
    validations: {
        item: {
            name: { required },
            link: { required },
            // image: { required },
        },
    },
    data() {
        return {
            item: {
                name: null,
                link: null,
            },
            validationmessage: {
                name: 'Name is required',
                link: 'Link is required',
                // image: 'Image is required',
            },
            type: "text",
            inputPlaceholder: "Search testimonial",
            classname: "default prefixicon",
            dropBtn: "btnbasic",
            clearClass: "btnplain",
            btninvitecls: "btnprimary",
            filter: "",
            selected: "",
            row: "",
            page: 1,
            per_page: 6,
            selectedRows: [],
            isView: false,
            submitted: false,
            rowviewdata: [],
            imageurl: "usericon.png",
            fileuploadid: "profileuploader",
            avatar: null,
            filedata: null,
            columns: [
                { label: "Icon", headerAlign: "left", align: "left", component: imageIconVue },
                {
                    label: "Name",
                    field: "name",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                {
                    label: "Link",
                    field: "link",
                    align: "left",
                    headerAlign: "left",
                    headerClass: "class-in-header second-class",
                    sortable: false
                },
                { label: "", headerAlign: "left", align: "right", component: EditBtn },
                { label: "Action", headerAlign: "left", align: "left", component: Action },
            ],
            rows: [],
        };
    },
    computed:{
        ...mapGetters("adminfeaturedin",["featuredInData"])
    },
    watch:{
        featuredInData:{
            immediate:true,
            handler(featuredInData){
                console.log('featuredInData',featuredInData);
                this.rows = featuredInData;
            }
        }
    },
    methods: {
        ...mapActions("adminfeaturedin", ["getfeaturedInData","createfeaturedInData","getSinglefeaturedInData","updatefeaturedInData","deletefeaturedInData"]),

        getfiledata(e) {
            this.filedata = e.target.files[0];
            let image = e.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.avatar = e.target.result;
            };
        },

        createFeaturedIn() {
            this.item = {
                name: null,
                link: null
            }
            this.submitted = false;
            this.togglePopUp()
        },

        togglePopUp() {
            this.isView = !this.isView;
        },

        handleFeaturedIn(){
            let vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                const formData = new FormData();
                let attachment = this.filedata;
                formData.append("Data", JSON.stringify(this.item));
                formData.append("image", attachment);
                this.createfeaturedInData(formData)
                .then(response => {
                    this.togglePopUp();
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },

        handleFeaturedInUpdate(){
            let vm = this;
            this.submitted = true;
            if (this.$v.item.$invalid) {
                this.$v.item.$touch();
            } else {
                const formData = new FormData();
                let attachment = this.filedata;
                formData.append("Data", JSON.stringify(this.item));
                formData.append("image", attachment);

                this.updatefeaturedInData({'id':this.item.id,'formData':formData})
                .then(response => {
                    this.togglePopUp();
                    this.$toast.open({
                        message: response.message,
                        type: "success",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                })
                .catch(err => {
                    this.$toast.open({
                        message: err.response.data.message,
                        type: "error",
                        duration: 5000,
                        dismissible: true,
                        position: "top"
                    });
                });
            }
        },
        onEditRow(id){
            this.getSinglefeaturedInData({id:id})
            .then(response=>{
                console.log('response',response)
                this.item = response.data;
                if (response.data.image) {
                    this.avatar = response.data.base_url + "/" + response.data.image;
                }
                this.togglePopUp();
            });
        }
    },
    created() {
        this.getfeaturedInData();
        this.$root.$on("getEditData", (row) => {
            this.onEditRow(row.id);
        });
    },
    beforeDestroy() {
        this.$root.$off("getEditData", this.fn);
    },
}
</script>