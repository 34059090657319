<template>
  <div>
    <div class="influencercategory">
      <div class="content-header">
        <div class="page-header">
          <text-component :class="h2txtclass">Influencer Category</text-component>
          <!-- <div class="page-header-right">
                        <a href="#create-invoice"><button-component :clsname="btninvitecls">Add Influencer</button-component></a>
          </div>-->
        </div>
      </div>
      <div class="content">
        <div class="box paddingo margino">
          <div class="box-content">
            <div class="header">
              <select-component
                class="txtgroup paddingo col-12 col-md-2 mr-1"
                :options="categorydata"
                v-model="category"
                :placeholder="selectplaceholder"
                :multiple="false"
              >Category</select-component>
              <select-component
                class="txtgroup paddingo col-12 col-md-2 mr-1"
                :options="dropPlatformList"
                v-model="platform"
                :placeholder="selectplaceholder"
                :multiple="false"
              >Platform</select-component>
              <select-component
                label="name"
                class="txtgroup paddingo col-12 col-md-2 mr-1"
                :options="cities"
                v-model="citySelect"
                :placeholder="selectplaceholder"
                :multiple="false"
              >Region</select-component>
              <div class="header-right">
                <button-component v-if="checkPermission('influencer_category_create')" @onBtnClick="popUp" :clsname="btninvitecls">Assign Category</button-component>
                <button-component :clsname="btninvitecls">
                  <export-excel
                      class="btn btn-default"
                      :data="formatedData(rows)"
                      :fields="json_fields"
                      worksheet="My Worksheet"
                      :name="excelFileName">
                      Export Data
                  </export-excel>
                </button-component>
              </div>
            </div>
            <hr class="hrline" />
            <div class="datatable-main">
              <div class="data-table-header">
                <div class="data-table-header-inner">
                  <search-bar
                    class="header-searchbar"
                    :classname="classname"
                    :type="type"
                    :inputPlaceholder="inputPlaceholder"
                    v-model="filter"
                  ></search-bar>
                </div>
              </div>
              <datatable-component
                class="dtable-main"
                :selected-rows="selectedRows"
                :actionid="actionid"
                :columns="columns"
                :cboxindeterminatecls="cboxindeterminatecls"
                :actionText="actionText"
                :rows="rows"
                :clsname="clsname"
                :btnText="btnText"
                :type="type"
                :inputPlaceholder="inputPlaceholder"
                :classname="classname"
                :dropBtn="dropBtn"
                :dropdownText="dropdownText"
                :statusClear="statusClear"
                :clearClass="clearClass"
                :filter="filter"
                :page="page"
                :per_page="per_page"
                @onrowclick="navigateDetail"
              ></datatable-component>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <form @submit.prevent="assigncategory">
        <div :class="{'modal-window': true , 'popupopen': isPopupOpen}">
          <div class="modal-window-main">
            <a title="Close" class="modal-close">
              <img
                @click="popUp"
                src="../../../assets/image/cancel.svg"
                class="cancel-icon"
                alt="cancel-icon"
              />
            </a>
            <div class="popheader">
              <text-component :class="h2txtclass">Assign Category</text-component>
            </div>
            <div class="row">
              <!-- <input-component class="txtgroup col-12 col-md-6 default">Influencer Name</input-component> -->
              <select-component
                required="true"
                class="txtgroup col-12 col-md-6"
                :helptext="!$v.influencer.required && submitted ? validationmessage.influencer: false"
                :labelclass="{'validationerr': !$v.influencer.required && submitted}"
                :inputclass="{'error': !$v.influencer.required && submitted, 'dropdownmenu': true}"
                :options="influencerdata"
                v-model="influencer"
                :placeholder="selectplaceholder"
                :multiple="false"
                @handlevalue="changeInfluencer()"
              >Influencer</select-component>
              <select-component
                required="true"
                class="txtgroup col-12 col-md-6"
                :helptext="!$v.categories.required && submitted ? validationmessage.categories: false"
                :labelclass="{'validationerr': !$v.categories.required && submitted}"
                :inputclass="{'error': !$v.categories.required && submitted, 'dropdownmenu': true}"
                :options="categorydata"
                v-model="categories"
                :placeholder="selectplaceholder"
                :multiple="true"
              >Categories</select-component>
            </div>
            <hr class="hrline" />
            <div class="modal-footer">
              <div>
                <button-component buttontype="submit" :clsname="btninvitecls">Save</button-component>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- End of Modal -->

      <!-- Modal -->
        <div :class="{'modal-window': true , 'popupopen': isCategory}">
            <div class="modal-window-main">
                <a title="Close" class="modal-close">
                <img
                    @click="showcategory"
                    src="../../../assets/image/cancel.svg"
                    class="cancel-icon"
                    alt="cancel-icon"
                />
                </a>
                <div class="popheader">
                  <text-component :class="h2txtclass">Influencer Category</text-component>
                </div>
                <div v-if="categoryusername.length <= 0" class="col-12">
                  <div class="tab-no-data-class">Currently no categories are added</div>
                </div>
                <div v-else class="selected-row-category">
                  <ul class="selected-row-category-list">
                    <li v-for="cdata in categoryusername" :key="cdata.id">{{cdata.name}}</li>
                  </ul>
                </div>
                <!-- <hr class="hrline" />
                <div class="modal-footer">
                  <div>
                      <button-component @onBtnClick="showcategory"  :clsname="'btnprimary'">Save</button-component>
                  </div>
                </div> -->
            </div>
        </div>
    <!-- End of Modal -->
    </div>
  </div>
</template>


<script>
import DatatableComponent from "../../../BasicComponents/Datatable/dtable.vue";
import ActionBtn from "./categoryactionbtn.vue";
import TextComponent from "../../../BasicComponents/Text/Text.vue";
import ButtonComponent from "../../../BasicComponents/Button/Button.vue";
import SelectComponent from "../../../BasicComponents/select/select";
import searchBar from "../../../BasicComponents/Input/Input.vue";
import SelectRow from "./selectrow.vue";
import SelectAll from "./selectall.vue";
import UserIcon from "./profileicon";
import Categoryname from "./influencercategoryname.vue";
import { eventBus } from "../../../main";
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  props: {},
  components: {
    DatatableComponent,
    TextComponent,
    searchBar,
    ButtonComponent,
    SelectComponent
  },
  computed: {
    ...mapGetters("manageinfluencer", ["categoryData", "registerInfluencer"])
  },
  validations: {
    categories: { required },
    influencer: { required }
  },
  data() {
    return {
      influencerdata: [],
      categorydata: [],
      categories: [],
      validationmessage: {
        categories: "category is required",
        influencer: "influencer is required"
      },
      submitted: false,
      isCategory: false,
      influencer: "",
      actionid: "moreactionbtn",
      cboxindeterminatecls: "cboxindeterminate",
      actionText: "More actions",
      clsname: "btnsort",
      btnText: "Sort",
      type: "text",
      selectplaceholder: "Select...",
      inputPlaceholder: "Search Influencer",
      classname: "default prefixicon",
      dropBtn: "btnbasic",
      dropdownText: "Sub Category",
      statusClear: "Clear",
      clearClass: "btnplain",
      txtclassname: "default",
      btninvitecls: "btnprimary",
      dropCat: "btnbasic",
      dropCatText: "Main Category",
      statusid: "btnstatus",
      filter: "",
      selected: "",
      row: "",
      category: "",
      platform: "",
      citySelect: "",
      h2txtclass: "h2",
      companyname: "Glan Pharma Pvt. Ltd.",
      isMobileMenu: false,
      isPopupOpen: false,
      page: 1,
      per_page: 5,
      selectedRows: [],
      columns: [
        // { label: "", component: SelectRow, headerComponent: SelectAll },
        {
          label: "Profile Photo",
          component: UserIcon,
          align: "left",
          headerAlign: "left"
        },
        {
          label: "Influencer’s Name",
          field: "name",
          align: "left",
          headerAlign: "left",
          headerClass: "class-in-header second-class",
          sortable: false
        },
        {
          label: "Email Address",
          field: "email",
          align: "left",
          headerAlign: "left",
          sortable: false
        },
        {
            label: "Mobile",
            // field: "phone",
            representedAs: function (row) {
                return (row.phone)?row.prefix+' '+row.phone:'-';
            },
            align: "left",
            headerAlign: "left",
            headerClass: "class-in-header second-class",
            sortable: false
        },
        // {
        //   label: "Action",
        //   headerAlign: "right",
        //   align: "right",
        //   component: ActionBtn
        // }
      ],
      rows: [],
      rowdata: [],
      categoryuser: [],
      categoryusername: [],
      dropCatList: [
        { id: "0", value: "Active" },
        { id: "1", value: "Inactive" }
      ],
      genderoption: [
        {
          id: "1",
          label: "Male"
        },
        {
          id: "2",
          label: "Female"
        },
        {
          id: "3",
          label: "Others"
        }
      ],
      json_fields: {
          'Influencer Name': 'name',
          'Email': 'email',
          'Contact' : 'phone',
          'Categories' : 'categories',
      },
      excelFileName: 'CategoryInfluencer.xls',
      dropPlatformList: [
        { id: "all", label: "All" },
        { id: "instagram", label: "Instagram" },
        { id: "facebook", label: "Facebook" },
        { id: "twitter", label: "Twitter" },
        { id: "youtube", label: "Youtube" },
      ],
      cities:[],
    };
  },
  watch: {
    categoryData: {
      immediate: true,
      handler(categoryData) {
        this.$data.categorydata = this.setcategory(categoryData);
      }
    },
    category: {
      immediate: true,
      handler(category) {
        this.filterData();
      }
    },

    platform: {
      immediate: true,
      handler(platform) {
          this.filterData();
      }
    },
    citySelect: {
      immediate: true,
      handler(citySelect) {
          this.filterData();
      }
    },

    registerInfluencer: {
      immediate: true,
      handler(registerInfluencer) {
        this.$data.rowdata = registerInfluencer;
        this.filterData();
        // this.$data.rows = registerInfluencer;
        this.$data.influencerdata = this.setinfluencer(registerInfluencer);
      }
    },

    selectedRows: {
      immediate: false,
      handler(selectedRows) {
        // console.log('asdasdasdasdasdasdasdasd')
        let selectedRowsIds = [];
        selectedRows.forEach(row => {
          selectedRowsIds.push(row.id);
        });
        this.selectedRowsIds = selectedRowsIds;
        this.addSelectedIds(selectedRowsIds);
      }
    }
  },
  created: function() {
    this.getcategories();
    this.getregisteredinfluencer();
    this.getcity({id: 1}).then(response=>{
      this.cities = response.data;
    });
  },

  methods: {
    ...mapActions("manageinfluencer", [
      "getcategories",
      "getregisteredinfluencer",
      "assigncattoinfluencer"
    ]),
    ...mapActions("commonfunction", ["getcity"]),

    navigateDetail(row) {
      this.categoryusername = []  
      this.showcategory()
      let selectedinfluencer = row.id;
      let influencer = this.registerInfluencer.filter(row=>{
        return row.id==selectedinfluencer
      });
      if(influencer[0].influencer_category){
        let categories = influencer[0].influencer_category.categories;
        categories = categories.split(',').map(x=>+x);
        if(categories.length){
          this.categoryuser = categories;
        }else{
          this.categoryuser = [];
        }
      }else{
        this.categoryuser = [];
      }

      for (let i = 0; i < this.categoryuser.length; i++) {
        for (let j = 0; j < this.categorydata.length; j++) {
          if(this.categoryuser[i] == this.categorydata[j].id){
            // console.log("mathched"+this.categoryuser[i])
            this.categoryusername.push({"id":this.categorydata[j].id, "name":this.categorydata[j].label})
          } 
        }
        // console.log("mathched"+this.categoryuser[i])
      }
    },

    showcategory(){
      if(this.isCategory == false){
        this.isCategory = true
      }
      else{
        this.isCategory = false
      }
    },

    filterData(){
      let category = this.category;
      let platform = this.platform;
      let citySelect = this.citySelect;
      if (category || platform || citySelect) {
        let rows = this.rowdata.filter(row=>{
          let categoryFilter = 1;
          let platformFilter = 1;
          let cityFilter = 1;
          if(category){
            categoryFilter = 0;
            if(row.influencer_category){
              categoryFilter = (row.influencer_category.categories.split(',').map(x=>+x).includes(category))
            }
          }
          if(citySelect){
            cityFilter = 0;
            if(row.user_profile){
              cityFilter = (row.user_profile.city == citySelect);
            }
          }
          if(platform && platform != 'all'){
            platformFilter = 0;
            if(row.user_social_account){
              row.user_social_account.forEach(element => {
                  if(platform == element.platform){
                      platformFilter = 1;
                  }
              })
            }
          }
          return (platformFilter && categoryFilter && cityFilter)?1:0;
        });
        this.rows = rows;
      }else{
        this.rows = this.rowdata;
      }
    },

    changeInfluencer(){
      // console.log(this.influencer)  
      let selectedinfluencer = this.influencer;
      let influencer = this.registerInfluencer.filter(row=>{
        return row.id==selectedinfluencer
      });
      if(influencer[0].influencer_category){
        let categories = influencer[0].influencer_category.categories;
        categories = categories.split(',').map(x=>+x);
        if(categories.length){
          this.categories = categories;
        }else{
          this.categories = [];
        }
      }else{
        this.categories = [];
      }     
    },
    
    assigncategory() {
      let vm = this;
      this.submitted = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        // console.log("submitted");
        this.assigncattoinfluencer({
          categories: this.categories.toString(),
          user_id: this.influencer
        })
          .then(response => {
            // console.log(response);
            vm.getregisteredinfluencer();
            this.isPopupOpen = false;
            this.$toast.open({
              message: response.message,
              type: "success",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          })
          .catch(err => {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "top"
            });
          });
      }
    },

    setcategory(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          label: value.name
        });
      });
      return newarray;
    },

    setinfluencer(data) {
      let newarray = [];
      data.map(value => {
        newarray.push({
          id: value.id,
          label: value.name
        });
      });
      return newarray;
    },

    popUp() {
      this.influencer = null;
      this.categories = null;
      this.submitted = false;
      this.isPopupOpen = !this.isPopupOpen;
      // if (this.isPopupOpen == true) {
      //   this.isPopupOpen = false;
      // } else {
      //   this.isPopupOpen = true;
      // }
    },

    formatedData(data){
      let returnArray = []; 
      if(data.length){
        data.forEach(row => {
          let categories = '';
          if(row.influencer_category){
            categories = this.getCategoriesName(row.influencer_category.categories);
          }
          let phone = (row.phone)?"'"+row.prefix+row.phone+"'":'';
          returnArray.push({
            'name':row.name,
            'email':row.email,
            'phone':phone.toString(),
            'categories':categories,
          });
        });
      }
      return returnArray;
    },

    getCategoriesName(ids){
      ids = ids.split(',')
      let categoryText = '';
      this.categoryData.forEach(row => {
        // selectedRowsIds.push(row.id);
        if(ids.includes(row.id.toString())){
          categoryText += (categoryText)?', '+row.name:row.name;
        }
      });
      // console.log('categoryText',categoryText);
      // console.log('categoryText',ids);
      return categoryText;
    }
  }
};
</script>
